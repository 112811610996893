.academic-page {
  background-color: #2f2a38;
  height: 100%;
  width: 100%;
}

.experience {
  height: 150vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 80px;
  position: relative;
}

.timeline-main-container {
  border-left: 2px solid #595166;
  margin-left: 40%;
  position: relative;
}

.xyz::before {
  content: "";
  border-radius: 50%;
  height: 20px; 
  width: 20px; 
  border: 2px solid #595166;
  background-color: #2f2a38;
  position: absolute; 
  left: -63px; 
}

.xyz {
  margin-left: 51px;
  position: relative; /* Added */
}

.xyz:nth-child(1)::before {
  top: 10px; 
}

.xyz:nth-child(2)::before {
  top: 10px; 
}

.xyz:nth-child(3)::before {
  top: 10px; 
}

.xyz:nth-child(4)::before {
  top: 10px; 
}

.timeline-main-container h1{
  margin-top: 5%;
  color:#c766ff;
  text-shadow: black 1px 1px 2px;
}
.timeline-main-container h2{
  color:white;
  text-shadow: black 1px 1px 2px;
}

.timeline-main-container p{
  color:#B6ABC4;
  text-shadow: black 1px 1px 2px;
  width: 60%;
}


.date {
  position: absolute;
  left: -400px; 
  top: 20%;
  transform: translateY(-50%);
  white-space: nowrap; 
  color: white; 
  font-size: 1.5em;
  font-weight: bold;
  background: linear-gradient(to right, #c766ff, #9932d5);
  border-radius: 20px;
  padding: 5px 10px; 
  text-shadow: black 1px 1px 2px;
}

.academic-title {
  color: white;
  text-align: center;
  font-size: 2.5em;
}

.projects {
  height: 100vh;
  background: linear-gradient(to right, #c766ff, #9932d5);
  display: flex;
  flex-direction: column;
  top: 60px;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  margin-top: 10%;
}

.skills {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 2%;
}

.skills-grid {
  align-self: center;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.335%;
  grid-template-rows: 50% 50%;
  gap: 50px 40px;
  grid-auto-flow: row;
  grid-template-areas:
    "skill1 skill2 skill3"
    "skill4 skill5 skill6";
  width: 50%;
  height: 50%;
  margin-left: -5%;
  margin-top: 5%;
}

.skill1,
.skill2,
.skill3,
.skill4,
.skill5,
.skill6 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #595166;
  color: white;
  font-size: 1.5rem;
  border-radius: 10px;
  flex-direction: column;
}

.skill1 img,
.skill2 img,
.skill3 img,
.skill4 img,
.skill5 img,
.skill6 img {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 40%;
  height: 90%;
  margin: 0;
  padding: 0;
}

.skill1 h3,
.skill2 h3,
.skill3 h3,
.skill4 h3,
.skill5 h3,
.skill6 h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 40%;
  height: 90%;
  margin: 0;
  padding: 0;
}

.skill1 {
  grid-area: skill1;
}

.skill2 {
  grid-area: skill2;
}

.skill3 {
  grid-area: skill3;
}

.skill4 {
  grid-area: skill4;
}

.skill5 {
  grid-area: skill5;
}

.skill6 {
  grid-area: skill6;
}

.carousel-container{
  width: 50%; 
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  border-radius: 60px;
}
.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  position: relative;
}

.carousel-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; 
  transition: opacity 0.5s ease; 
}

#legend {
  position: absolute; 
  top: 0; 
  color: white; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 20px;
  opacity: 0; 
  width: 80%; 
  height: 100%;
  border-radius: 20px;
  transition: opacity 0.5s ease; 
  margin: 0;
}

.carousel-slide:hover #legend {
  opacity: 1;
  background-color: rgb(89, 81, 102, 0.3);
  width: 100%;
  height: 100%;
  
}

.carousel-slide:hover #legend p{
  width: 80%;
}


.carousel-slide:hover .carousel-img {
  opacity: 0.3; 
}
