body,
html {
  margin: 0;
  font-family: "Montserrat", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: black 1px 1px 2px;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 768px) {
  body,
  html {
    font-size: 14px;
  }

  .container {
    padding: 10px;
  }

  .header {
    font-size: 1.5em; 
    text-align: center; 
  }

  .content {
    font-size: 1em; 
    padding: 10px; 
  }

  .footer {
    font-size: 0.875em; 
    text-align: center; 
  }
}

@media (max-width: 480px) {
  body,
  html {
    font-size: 12px; 
  }

  .container {
    padding: 5px; 
  }

  .header {
    font-size: 1.2em; 
  }

  .content {
    font-size: 0.875em; 
  }

  .footer {
    font-size: 0.75em;
  }
}
