.background {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #2f2a38;
}

.contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  width: 100%;
  background-image: linear-gradient(to right, #c766ff, #9932d5);
  top: 35%;
}

.headshot {
  position: relative;
  left: 0;
  margin-left: -20%;
  z-index: 2;
  border-color: white;
  border-width: 5px;
  border-radius: 90%;
  border-style: solid;
  overflow: hidden;
  width: 350px;
  height: 350px;
}

.headshot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-info {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  top: 0%;
  right: 0;
  margin-left: 10%;
  z-index: 2;
  color: white;
}

.contact-info h1 {
  font-size: 2.7rem;
  font-weight: bold;
}

.contact-info .information {
  display: flex;
  flex-direction: column;
  margin-top: -10%;
  gap: 0px;
}

.contact-info p {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 2%;
}

.email .icon {
  width: 9%;
  height: 9%;
  padding-right: 4%;
}

.phone .icon {
  width: 8%;
  height: 8%;
  padding-right: 5%;
}

.resume {
  display: flex;
  align-items: center;
  gap: 5px;
}


.resume .icon {
  width: 50px;
  height: auto;
  margin-left: -15%;
}

.socials {
  position: relative;
  display: flex;
  gap: 25px;
}

.github {
  width: 60px;
  height: fit-content;
  background-color: #c766ff;
  border-radius: 90%;
}

.linkedin {
  width: 60px;
  height: fit-content;
  background-color: #c766ff;
  border-radius: 90%;
}
