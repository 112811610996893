.musician-page {
  background-color: #2f2a38;
  height: 100%;
  width: 100%;
}

.performance-experience {
  display: flex;
  flex-direction: column;
  top: 60px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  background: linear-gradient(to right, #c766ff, #9932d5);
}

.performance-image {
  position: relative;
  background-color: #2f2a38;
  border: #2f2a38;
  border-style: solid;
  border-radius: 20px;
  width: 80%;
  overflow: hidden;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  margin-bottom: 40px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.performance-image img {
  width: 100%;
  height: auto;
  border-radius: 15px; 
  object-fit: cover; 
}

.performance-image p,
.performance-image h1,
.performance-image h2 {
  position: absolute; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.performance-image p {
  top: 25%; 
  left: 5%;
  color: white;
}

.performance-image h1 {
  top: 35%;
  left: 5%;
  font-size: 3em;
  color: white;
  width: 50%;
}

.performance-image h2 {
  bottom: 20%; 
  left: 5%;
  color: #c766ff;
}

#cmb-h1{
  margin: 0;
  top: 35%;
  width: 40%;
}


.musician-title {
  margin-top: 3%;
  color: white;
  text-align: center;
  font-size: 2.5em;
}

.teaching {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.teaching-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0% 15%;
  grid-auto-flow: row;
  grid-template-areas: "lcs waverly";
  width: 50%;
  height: 50%;
  margin-top: 5%;
}

.lcs, .waverly {
  background:#595166;
  border-radius: 50px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12%;
  overflow: hidden;
}

.lcs h1, .waverly h1{
  font-size: 2.5em;
  text-align: center;
  margin: 0%;
}

.lcs h2, .waverly h2{
  font-size: 1.25em;
  width: 70%;
  text-align: center;
  border: #6d637a;
  background-color: #6d637a;
  border-width: 2px;
  border-radius: 20px;
  margin-top: 10%;
}

.lcs h3, .waverly h3{
  position: relative;
  font-size: 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  background: linear-gradient(to right, #c766ff, #9932d5);
  width: 100%;
  flex-grow: 1;
  margin: 0;
}

.lcs {
  grid-area: lcs;
}

.waverly {
  grid-area: waverly;
}
