.home-page{
  position: relative;
  height: 100%;
  width: 100%;
}

.split {
  height: calc(100vh - 60px); /* Adjust height to account for the navbar */
  width: 100vw;
  position: relative;
  z-index: 0;
  top: 60px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
}

.dimmer {
  flex: 1;
  filter: grayscale(100%);
  position: relative;
  overflow: hidden;

}

.dimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(199, 102, 255, 0.3); 
  opacity: 0; 
  transition: opacity 0.8s ease; 
}

.dimmer:hover{
  filter: grayscale(0%);
  transition: filter 2s ease;
}

.dimmer:hover::before {
  opacity: 1; /* Show the overlay on hover */
}

.student-side {
  left: 0;
  transition: transform 1s ease;
}
.student-side:hover{
  transform: scale(1.1);
  z-index: 2;
  transition: transform 1s ease;
}

.musician-side {
  right: 0;
  transition: transform 1s ease;
  z-index: 1;
}

.musician-side:hover{
  transform: scale(1.1);
  z-index: 2;
  transition: transform 1s ease;
}

.student-side img, .musician-side img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.student-side .navigation, .musician-side .navigation {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: inherit; 
  text-decoration: inherit;
}

.picture-labels {
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  color: white;
  font-size: 2.5em;
  opacity: 0; 
  transition: opacity 0.8s ease; 
}

.dimmer:hover .picture-labels {
  opacity: 1; /* Show the text on hover */
}