.navbutton {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: bold;
    font-size: 1.7rem;
    top: 50%;
    font-family: Garamond, serif;
}

.navbutton:hover {
    background-color: black;
    color: white;
}

