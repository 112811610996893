.navbar {
  background-color: #221d2b;
  z-index: 80;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 10%;
  overflow: hidden;
  color: white;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #C766FF, #9932D5); 
  z-index: 79; 
}

.navbar .name-title {
  font-weight: bold;
  font-size: 2.5em;
  padding-left: 2%;
}

.navbar .contact-title {
  position: relative;
  padding-right: 3%;
  font-size: 1.7em;
}

.navbar .musician-text {
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30%;
}

.navbar .musician-info {
  margin: 10%;
  font-weight: 400;
}

.navbar .academic-text {
  position: relative;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30%;
}

.navbar .academic-info {
  margin: 10%;
  font-weight: 400;
}

.navbar a:hover{
  color:  #595166;
}
